<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@laam/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const variants = cva('animate-pulse rounded-medium bg-neutral-100', {
	variants: {
		color: {
			light: 'bg-gray-100',
			dark: 'bg-gray-200',
		},
	},
	defaultVariants: {
		color: 'light',
	},
});

export type SkeletonVariants = VariantProps<typeof variants>;
interface SkeletonProps {
	class?: HTMLAttributes['class'];
	color?: SkeletonVariants['color'];
}

const props = defineProps<SkeletonProps>();
</script>

<template>
	<div :class="cn(variants({ color }), props.class)" />
</template>
